import React, { useContext, useState, useEffect, createContext } from 'react';
import axios from 'axios';
import { API_URL, ACCESS_TOKEN_LOCAL_STORAGE_KEY } from '../constants';

const AuthContext = createContext();

const AuthProvider = ({children}) => {

  const register = (email, password) => {
    return _makeRequest('post', 'auth/register', {
      email: email,
      password: password
    });
  }

  const login = (email, password) => {
    return _makeRequest('post', 'auth/login', {
      email: email,
      password: password
    });
  }

  const _makeRequest = (method, endpoint, data) => {
    let requestConfig = {
      url: API_URL + endpoint,
      method: method,
    };
    if (data != undefined) {
      requestConfig['data'] = data;
    }
    return axios(requestConfig).then((response) => {
      if (response.status === 200) {
        _saveAccessToken(response.data['access_token']);
        return response.data;
      } else {
        return false;
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const _saveAccessToken = (accessToken) => {
    window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
  }

  const _retrieveAccessToken = () => {
    return window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  }

  const getAccessToken = () => {
    return _retrieveAccessToken();
  }

  return (
    <AuthContext.Provider value={{
      login,
      register,
      getAccessToken
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
