import React, { useContext, useState, useEffect, createContext } from 'react';
import axios from 'axios';
import { API_URL, AUTH_FAIL_REDIRECT_URL } from '../constants';
import { AuthContext } from '../context/AuthContext';

const LinkContext = createContext();

const LinkProvider = ({children}) => {
  const authContext = useContext(AuthContext);

  const getLinks = () => {
    return _makeRequest('get', 'link');
  }

  const getLink = (linkId) => {
    return _makeRequest('get', 'link/' + linkId);
  }

  const getLinkAggregate = (linkId) => {
    return _makeRequest('get', 'link/agg/' + linkId);
  }

  const createLink = (linkUrl) => {
    return _makeRequest('post', 'link', {
      url: linkUrl
    });
  }

  const updateLink = (linkId, updatedUrl) => {
    return _makeRequest('patch', 'link/' + linkId, {url: updatedUrl});
  }

  const deleteLink = (linkId) => {
    return _makeRequest('delete', 'link/' + linkId);
  }

  const _makeRequest = (method, endpoint, data) => {
    let accessToken = authContext.getAccessToken();
    if (!accessToken) {
      window.location.href = AUTH_FAIL_REDIRECT_URL;
    }
    let requestConfig = {
      url: API_URL + endpoint,
      method: method,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    };
    if (data != undefined) {
      requestConfig['data'] = data;
    }
    return axios(requestConfig).then((response) => {
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return false;
      }
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status) {
        if (error.response.status === 403) {
          window.location.href = AUTH_FAIL_REDIRECT_URL;
        }
      }
    });
  }

  return (
    <LinkContext.Provider value={{
      getLink,
      getLinks,
      getLinkAggregate,
      createLink,
      updateLink,
      deleteLink
    }}>
      {children}
    </LinkContext.Provider>
  );
};

export { LinkProvider, LinkContext };
