import "./src/styles/global.css"
import React from "react"
import { LinkProvider } from "./src/context/LinkContext";
import { AuthProvider } from "./src/context/AuthContext";

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <LinkProvider>
        {element}
      </LinkProvider>
    </AuthProvider>
  );
}